/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.hide-native-summary-arrow {
  summary::-webkit-details-marker {
    display: none;
  }

  summary {
    list-style: none;
  }

  summary::marker {
    display: none;
  }
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1em;
  padding-right: 2.5rem;
  position: relative;
}

.custom-select::-ms-expand {
  display: none;
}

/* Fix dropdown alignment */
select.custom-select option {
  position: absolute;
  left: 0;
  min-width: 100%;
  box-sizing: border-box;
}
