*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  scroll-behavior: smooth;
}

button {
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
}

// TODO: review if this affects the app for professional stuff
// a{
//   &,
//   &:visited,
//   &:active{
//       text-decoration: unset;
//       color: unset;
//   }
// }

body {
  box-sizing: border-box;
  font-size: 1.6rem;
  font-family: $font-primary;
  color: $color-text;
  background-color: $color-background;
  overflow-x: hidden;
  @include custom-scroll;
}

input {
  font-family: $font-primary;
  font-size: 1.6rem;
}

::selection {
  background: #d1fae5;
  color: black;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #d1fae5;
  color: black;
  /* Gecko Browsers */
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  /* show a hand cursor on hover; some argue that we
  should keep the default arrow cursor for buttons */
  cursor: pointer;
}

.content {
  padding: 2rem 2.4rem;
  max-width: 900px;
  margin: auto;
}

.custom-scroll {
  @include custom-scroll;
  cursor: e-resize;
}

.custom-scroll-2 {
  @include custom-scroll-2;
}

.crisp {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

router-outlet {
  display: contents;
}

router-outlet + * {
  flex-grow: 1;
}
