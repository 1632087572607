.menu-button{
    position: relative;
    &__button {

    }
    &__menu{
        z-index: 2;
        width: 230px;
        visibility: hidden;
        background-color: white;
        box-shadow: $box-shadow-1;
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 50px;
        border-radius: .5rem;
        &--open{
            visibility: visible;
        }
        &__button{
            padding: 2rem;
            text-align: center;
        }
    }

    &__overlay{
        position: fixed;
        top: 0;
        left: 0;
        background-color: black;
        opacity: 0;
        visibility: hidden;
        width: 100vw;
        height: 100vh;
        transition: .2s;
        z-index: 1;
        &--open {
            visibility: visible;
            display: block;
            opacity: .1;
        }
    }
}