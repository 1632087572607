.form{ 
    &__field{
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
    }
    &__label{
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        color: $color-primary-1;
        margin-bottom: .5rem;
    }
    &__input{
        padding: 0 1rem;
        height: 4rem;
        border-radius: .5rem;
        border: 1px solid $color-border;
    }
    &__actions{
        margin: 4rem 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}