// Color Variables ======================================
$green: $color-primary-6;
$gray-light: #eef1f3;

// Bar Variables ========================================
$bar-size: 5px;
$bar-radius: 60px;
$bar-bg: rgba(0,0,0,0.075);



// Bar ============================================
.progress-bar {
	border-radius: $bar-radius;
	overflow: hidden;
  width: 100%;

	span {
		display: block;
	}

}

.bar {
  background: $bar-bg;
}

.progress {
  // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
  background: $green;
  color: #fff;
  padding: $bar-size 0px;
  width: 50%;
}

// Vertical Centering ==============================
// You don't need this to make it work on your site.
.progress-bar {
  
}