.button-solid{
    transition: all .5s;
    padding: 2rem 4rem;
    border-radius: .5rem;
    background-color: $color-primary;
    color: white;
    cursor: pointer;
    // font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow-system-1;
    font-weight: 600;
    &--invert{
        color: $color-text;
        background-color: white;
    }
    i{
        margin-right: 2rem;
    }
    &:hover{
        transform: translateY(-1px);
        transform: scale(1.02);
        box-shadow: $box-shadow-system-3;
    }

    &--small{

    }

    &--disabled{
        opacity: .25;
    }

    &--intecc{
        background-color: #2456DC;
    }
}

.button-icon{
    font-size: 2rem;
    color: $color-primary;
    &--big{
        font-size: 5rem;
    }
    &--gray {
        color: #d0d0d0;
    }
}

.button-square{
    padding: 1rem;
    background-color: $color-primary;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    box-shadow: $box-shadow-system-1;
    cursor: pointer;
    transition: all .5s;
    text-align: center;
    &:hover{
        transform: scale(1.05);
        box-shadow: $box-shadow-system-5;
    }
    &--disabled{
        opacity: .5;
        &:hover{
            transform: none;
            box-shadow: $box-shadow-system-1;
            cursor: unset;
        }
    }
}

.button-link{
    display: block;
    font-weight: 600;
    padding: 1rem 1.8rem; 
    display: flex;
    align-items: center;;
    &,
    &:active,
    &:visited {
        color: $color-primary-6;
    }
    &:hover{
        text-decoration: underline;
    }

    i{
        margin-right: 1rem;
    }
    
}

.button-nav-back{
    &,
    &:active,
    &:visited{
        color: $color-primary;
    }
    display: flex;
    align-items: center;
    font-size: 2rem;
    transition: all .5s;
    &__icon{
        margin-right: 1rem;
    }
    &__label{

    }
    &:hover{
        transform: translateX(-1rem);
    }
}

