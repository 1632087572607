.element-client-img{
    height: 40px;
    width: 40px;
    border-radius: 999px;
    background: $color-primary-9;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    color:$color-primary-1;
    font-weight: 700;
}

.small-divider {
    border-radius: 999px;
    height: 2px;
    width: 30px;
    background-color: #2186EB;
    margin: 0 auto;
    margin-bottom: 2rem;
}


