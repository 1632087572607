.form {
  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
  &__label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: $color-primary-1;
    margin-bottom: 0.5rem;
  }
  &__input {
    padding: 0 1rem;
    height: 4rem;
    border-radius: 0.5rem;
    border: 1px solid $color-border;
    &:focus {
      border: 1px solid $color-primary-intecc;
    }
    &:focus-visible {
      outline: 1px solid $color-primary-intecc;
    }
    // outline: 1px solid $color-primary-intecc;
  }
  &__actions {
    margin: 4rem 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
