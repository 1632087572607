.form-input{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    &__label{
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        color: #002159;
        margin-bottom: .5rem;
    }
    &__input{
        padding: 0 1rem;
        height: 4rem;
        border-radius: .5rem;
        border: 1px solid #deecff;
    }
}