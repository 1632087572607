@import 'abstracts/variables';

.snackbar-success.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  @apply bg-green-600;
}
.snackbar-error.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  @apply bg-red-600;
}
.snackbar-alert.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  @apply bg-yellow-600;
}
.snackbar-info.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  @apply bg-intecc2;
}
