.tooltip {
    display:inline-block;
    position:relative;
    border-bottom:1px dotted #666;
    text-align:left;
}

.tooltip .top {
    min-width:300px; 
    top:-20px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:10px 20px;
    color: $color-text;
    background-color: white;
    font-weight:normal;
    font-size:1.6rem;
    border-radius:8px;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.8s;

    h3{
        margin-bottom: .5rem;
    }
    p{
        margin-bottom: 1rem;
        &:last-child {
            // margin-bottom: unset;
        }
    }
}





.tooltip:hover .top {
    visibility:visible; opacity:1;
}

.tooltip .top i {
    position:absolute;
    top:100%;
    left:50%;
    margin-left:-12px;
    width:24px;
    height:12px;
    overflow:hidden;
}



.tooltip .top i::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#EEEEEE;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
}



// custom
.tooltip .top--test-card{
  left: 0;
  margin-left: 135px;
}

.tooltip .top--test-card i{
  left: 13%;
}