// para salvar la barra de navegación
.container{

}

// margenes estandar
.content{
    padding: 0 2.5rem;
    max-width: 900px;
    margin: auto;
}

.separator{
    margin-top: 1rem;
}