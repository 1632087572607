@import '../vendors/include-media';

@mixin custom-scroll {
  @include media('>s'){
    scrollbar-color: $color-primary white;
    scrollbar-width: thin;
  
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      background-color: white; 
    }
  
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $color-gray-8;
      border: none;
      border-radius: 100px;
    }
  }
}

@mixin custom-scroll-2 {
    @include media('>s'){
      scrollbar-color: $color-primary white;
      scrollbar-width: thin;
    
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: #354fe7; 
      }
    
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: #F5F5F5;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: $color-gray-8;
        border: none;
        border-radius: 100px;
      }
    }
  }