.bulk-actions-bar{
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height .2s;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    @include media('<m') {
        display: grid;
        grid-template-columns: 1fr auto;
    }

    &--active{
        opacity: 1;
        max-height: 100px;
    }

    &__selected-count{
        font-weight: bold;
        font-size: 2rem;
    }

    &__actions{
        display: flex;
        align-items: center;
        margin-left: 1rem;
    }

    &__actions-end{
        display: flex;
        align-items: center;
        margin-left: 1rem;
        flex-grow: 1;
        justify-content: flex-end;
        @include media('<m') {
            margin-top: 1rem;
            margin-left: 0;
            grid-column: 1 / span 2;
            justify-content: stretch;
        }
    }

    &__action-button{

        border-radius: 999px;
        padding: .1rem;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            font-size: 2rem;
        }

        &:hover{
            opacity: .7;
        }

        &--small{
            i{
                font-size: 1.6rem;
            }
        }
    }

    
    &__action-button-text{
        width: 186px;
        height: 40px;
        border-radius: .5rem;
        padding: .1rem;
        display: flex;
        align-items: center;
        justify-content: left;
        background-color: $color-primary-6;
        color: white;
        // padding: .5rem 1rem;
        // padding: 1rem 1.5rem;
        font-weight: bold;
        box-shadow: $box-shadow-system-1;
        @include media('<m'){
            width: unset;
            width: 100%;
            justify-content: center;
        }


        &:hover{
            opacity: .7;
        }

        &__text{
            // @include media('<m'){
            //     display: none;
            // }
        }
        &__icon{
            font-size: 2rem;
            margin-right: 1.5rem;
            margin-left: 1.5rem;
        }
    }

    &__separator{
        margin: 0 0rem;
        margin-right: 1rem;
        height: 18px;
        width:  1px;
        background: $color-gray-6;
    }
}