.prismic-content{

  div[data-oembed-type="video"]{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 80%;
    margin: auto;
    margin-top: 2rem;

    @include media('<m'){
      max-width: 100%;
    }


    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  h2{
    font-family: $font-primary;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 2rem;
    text-align: center;
  }

  h3{
    margin-bottom: 1rem;
    margin-top: 3rem;
  }

  p{
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  ol, ul{
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  li{
    margin: 1rem 0;
  }
}