$font-primary: proxima-nova, sans-serif;
//$font-primary: 'Montserrat', sans-serif;



// COLORS
$black20: #202020;
$color-text: #222222;
$color-text-gray: #5f5f5f;
$color-background: #F5F7FA;
$color-primary: #1A2EFF;
$color-primary: #003472;
$color-primary-intecc: #354FE7;

$color-warning: #FFB300;

$color-light-green: #c6ffd9;
$color-dark-green: #02832d;

$color-accent: #c05bfa;
$color-bad: #ff6161;
$color-good: #63db77;


$color-bar-empty: #ffffff;
$color-bar-bad: #eb6060;
$color-bar-good: #7cd992;
$color-bar-optimum: #2cd5ff;
$color-bar-optimum: #0079d8;


// palette
$color-primary-0: #000530;
$color-primary-1: #002159;
$color-primary-2: #01337D;
$color-primary-5: #0967D2;
$color-primary-6: #2186EB;
$color-primary-9: #BAE3FF;

$color-vivid-blue: #00A1FF;

$color-red-5: #E12D39;


$color-gray-2: #323F4B;
$color-gray-6: #7B8794;
$color-gray-8: #CBD2D9;
$color-gray-9: #E4E7EB;
$color-gray-10: #F5F7FA;

$color-border: #deecff;

$color-primary-light-1: rgb(175, 182, 255);


// theme colors
$color-red: $color-red-5;


//SHADOWS

$box-shadow-1: 0px 5px 10px 0px rgba(54, 54, 54, 0.2);
$box-shadow-1-far: 0px 5px 15px 0px rgba(0,0,0,0.20);

$box-shadow-system-1: 0px 1px 3px rgba(0,0,0,.2);
$box-shadow-system-2: 0px 4px 6px rgba(0,0,0,.2);
$box-shadow-system-3: 0px 5px 15px rgba(0,0,0,.2);
$box-shadow-system-4: 0px 10px 24px rgba(0,0,0,.2);
$box-shadow-system-5: 0px 15px 35px rgba(0,0,0,.2);

$box-shadow-custom-1: 0 7px 30px -10px rgba(150,170,180,0.5);

