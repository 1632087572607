.switch {
  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 35px;
    height: 20px;
    background: $color-gray-8;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 18px;
    transition: 0.2s;
  }

  input:checked+label {
    background: $color-primary-6;
  }

  input:checked+label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 25px;
  }
}


