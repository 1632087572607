.title-screen {
    font-weight: 600;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 4rem;
}
.title-section{
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.heading-1{
    font-weight: 400;
    margin: 1rem 0;
    margin-top: 0rem;
    font-size: 3rem;
}
.heading-2{
    font-weight: 400;
    font-size: 2rem;
}

.center{
    text-align: center;
}