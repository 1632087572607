@mixin button {
    font-family: $font-primary;
    font-weight: 800;
    border: none;
    border-radius: 9999px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 1rem 2rem;
    
    &:focus{
        outline: none;
        opacity: .9;
    }
}

.button-cta-1{
    @include button;
    background: $color-primary;
    box-shadow: $box-shadow-1;
    color: white;
    transition: all .2s;
    &:hover{
        transform: scale(1.05);
        box-shadow: $box-shadow-1-far;
    }
    &:active{
        transform: scale(1.02);
        box-shadow: $box-shadow-1;
    }

}

.button-cta-icon{
    @include button;
    font-size: 1.2rem;
    background: $color-primary-6;
    box-shadow: $box-shadow-1;
    color: white;
    transition: all .2s;
    display: flex;
    align-items: center;
    &:hover{
        opacity: 0.9;
    }
    &:active{
        
    }

    i {
        margin-right: 2rem;
        
        font-size: 2rem;
    }
    span {
    }
}

.button-plain{
    @include button;
    background: transparent;
    transition: all .2s;
    &:hover{
        background: $color-primary;
        box-shadow: $box-shadow-1;
        color: white;
    }

}

.button-icon-plain{
    @include button;

    font-size: 2rem;
    color: $color-primary-6;
    transition: all .2s;
    padding: 1rem 1rem;
    height: 44px;
    width: 44px;

    &:hover{
        background: $color-primary-6;
        color: white;
    }

}

.button-cta-2{
    @include button;
    text-transform: unset;
    border-radius: .5rem;
    background-color: $color-primary-6;
    color:white;
    font-weight: 700;
    padding: 1.5rem 3rem;
    box-shadow: $box-shadow-system-1;
    transition: all .2s;

    border: 1px solid $color-primary-6;
    &:hover{
        transform: scale(1.01);
        box-shadow: $box-shadow-system-2;
    }

    &:disabled,
    button[disabled]{
        opacity: .5;
    }

    &--red{
        background: $color-red;
    }

    &--variant-1{
        background-color: white;
        color: $color-primary-6;
    }
}

.button-plain-2{
    @include button;
    text-transform: unset;
    border-radius: .5rem;
    color: $color-text;
    font-weight: 700;
    padding: 1.5rem 3rem;
    transition: all .2s;

    &:hover{
        opacity: .9;
    }


}

.button-plain-3{
    @include button;
    text-transform: unset;
    border-radius: .5rem;
    color: $color-text;
    font-weight: 700;
    padding: 1.5rem 3rem;
    transition: all .2s;
    text-transform: uppercase;

    i{
        margin-right: 1rem;
    }

    &:hover{
        opacity: .9;
    }


}

.button-close-dialog{
    border-radius: 999px;
    height: 30px;
    width: 30px;
    color: $color-text;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    &:hover{
        opacity: .8;
    }

    &--white{
        color: white;
    }

    &--big {
        font-size: 2.2rem;
    }

    &--rounded-background{
        height: 35px;
        width: 35px;
        padding: .5rem;
        background-color: rgba(black, .5);
        border-radius: 9999px;
    }

}

.button-icon-circular{
    border-radius: 999px;
    height: 30px;
    width: 30px;
    background: $color-primary-9;
    color: $color-primary-2;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &--white {
      background: white;
      color: $color-gray-2;
    }

    &--big {
      height: 35px;
      width: 35px;
    }
}



// new buttons

.cool-button-with-icon{
    background-color: $color-primary-5;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    color: white;
    padding: 1rem 1.5rem;
    box-shadow: $box-shadow-1;
    transition: all .2s;
    &__text{
        font-weight: 600;
    }
    &__icon{
        margin-right: 1.5rem;
    }

    &:hover{
        transform: translateY(-1px);
        opacity: .8;
    }

    &--loading{
        opacity: .8;
    }

    &--big{
        padding: 1.25rem 3rem;
    }
    &--big-2{
        padding: 2rem 4rem;
    }
    &--vivid {
        background-color: $color-vivid-blue;
    }

    &--upgrade{
        background-color: #354FE7;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.new-button-regular-with-icon{
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: $color-text;
    padding: 12px 1.5rem;
    // box-shadow: $box-shadow-system-1;
    transition: all .2s;
    margin: auto;

    

    &--full-width-mobile{
        @include media('<m'){
            width: 100%;
            justify-content: center;        
        }
    }

    &__text{
        font-weight: bold;
    }
    &__icon{
        margin-right: 1.5rem;
    }

        &__icon-no-margin-mobile{
          @include media('<m'){
            margin-right: 0;     
        }
    }

    &--collapsable {
        @include media('<m'){
            .new-button-regular-with-icon__text {
                display: none;
            }
            .new-button-regular-with-icon__icon {
                margin-right: 0;
            }
        }
    }



    &--loading{
        opacity: .8;
    }
}

.video-button {
    padding: 1rem 1.5rem;
    border-radius: .5rem;
    background-color: white;
    box-shadow: $box-shadow-system-1;
    display: flex;
    align-items: center;

    &__icon{
        margin-right: 1rem;
    }
    &__text{

    }
}

// .button-solid{
//     transition: all .5s;
//     padding: 2rem 4rem;
//     border-radius: .5rem;
//     background-color: $color-primary;
//     color: white;
//     cursor: pointer;
//     // font-weight: 600;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     box-shadow: $box-shadow-system-1;
//     font-weight: 600;
//     &--invert{
//         color: $color-text;
//         background-color: white;
//     }
//     i{
//         margin-right: 2rem;
//     }
//     &:hover{
//         transform: translateY(-1px);
//         transform: scale(1.02);
//         box-shadow: $box-shadow-system-3;
//     }

//     &--small{

//     }
// }