@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;800&display=swap');
// @import url("https://use.typekit.net/lci1msb.css");

// @font-face {
//   font-family: 'SF Pro Display';
//   font-weight: 700;
//   src: url("../assets/fonts/SFProDisplay-Bold.ttf") format('truetype');
// }
// @font-face {
//   font-family: 'SF Pro Display';
//   font-weight: 900;
//   src: url("../assets/fonts/SFProDisplay-Black.ttf") format('truetype');
// }